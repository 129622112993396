<template>
    <div class="users">
        <PageNavBar title="修改用户" />

        <van-form @submit="onSubmit">
            <van-cell-group inset>

                <van-field v-model="nickName" name="nickName" label="用户名" placeholder="用户名"
                    :rules="[{ required: true, message: '请填写用户名' }]" />

                <van-field v-model="phoneNumber" type="tel" name="phoneNumber" label="手机号" placeholder="手机号"
                    :rules="[{ required: true, message: '手机号' }]" />

                <van-field name="sex" label="性别">
                    <template #input>
                        <van-radio-group v-model="sex" direction="horizontal">
                            <van-radio name="0">男</van-radio>
                            <van-radio name="1">女</van-radio>
                        </van-radio-group>
                    </template>
                </van-field>

                <van-field name="roleId" label="角色">
                    <template #input>
                        <van-radio-group v-model="roleId" direction="horizontal" disabled>
                            <van-radio name="3">阅读者</van-radio>
                            <van-radio name="1">管理员</van-radio>
                        </van-radio-group>
                    </template>
                </van-field>

                <van-field name="status" label="状态">
                    <template #input>
                        <van-radio-group v-model="status" direction="horizontal">
                            <van-radio name="1">启用</van-radio>
                            <van-radio name="0">停用</van-radio>
                        </van-radio-group>
                    </template>
                </van-field>

            </van-cell-group>

            <div style="margin: 20px;">
                <van-button round block type="primary" native-type="submit">
                    提交
                </van-button>
            </div>

            <div style="margin: 20px;" :show="!!userId">
                <van-button round block type="danger" @click="onDelete">
                    删除
                </van-button>
            </div>
        </van-form>

    </div>
</template>

<script>
import PageNavBar from '../../components/PageNavBar'
import { getUserDetail, updateUser, deleteUser } from '../../api/user'
import { Dialog } from 'vant'

export default {
    name: 'EditUserPage',
    components: {
        PageNavBar,
    },
    props: {
    },
    setup() {
    },
    created() {
        let { id } = this.$route.params
        this.userId = id
        this.getUserDetail(id)
        
    },
    mounted() {
    },
    data() {
        return {
            userId: '0',
            avatar: "",
            userName: "",
            nickName: "",
            phoneNumber: "",
            roleId: '3',
            sex: '0',
            status: '1'
        }
    },
    methods: {
        async getUserDetail(userId) {
            let user = await getUserDetail(userId)
            if (user) {
                this.userId = user.userId
                this.avatar = user.avatar
                this.userName = user.userName
                this.nickName = user.nickName
                this.phoneNumber = user.phoneNumber
                this.roleId = user.roleId
                this.sex = user.sex
                this.status = user.status
            }
        },
        async onSubmit(user) {
            user.userId = ''+this.userId
            user.userName = user.phoneNumber
            user.avatar = this.avatar
            
            delete user.roleId

            let result = await updateUser(user)
            Dialog.alert({
                message: result.msg,
            }).then(() => {
                if (result.code == 200) {
                    this.goBackAndRemoveKeepAlive()
                }
            })
        },
        async onDelete() {
            Dialog.confirm({
                message: '是否确定删除该用户？删除后将无法恢复。',
            })
            .then(() => {
                // on confirm
                this.deleteUserById(this.userId)
            })
            .catch(() => {
                // on cancel
            });
        },
        async deleteUserById(userId) {
            let result = await deleteUser(userId)
            Dialog.alert({
                message: result.msg,
            }).then(() => {
                if (result.code == 200) {
                    this.goBackAndRemoveKeepAlive()
                }
            })
        }
    }
}
</script>

<style lang="stylus" scoped>
</style> 